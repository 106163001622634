import { Info } from "@mui/icons-material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import CameraAlt from "@mui/icons-material/CameraAlt"
import MenuIcon from "@mui/icons-material/Menu"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"

export default function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = React.useState(false)

  const pages = [
    {
      text: "Gallery",
      href: "/",
      icon: <CameraAlt />,
      group: "ภาพผลงาน",
    },
    {
      text: "ติดต่อเรา",
      href: "/contact",
      icon: <Info />,
      group: "ข้อมูลการติดต่อ",
    },
  ]

  const handleToggleDrawer = () => {
    setOpen(!open)
  }

  const handleCloseNavMenu = (target) => {
    navigate(target)
    setOpen(false)
  }

  const groupPages = pages.reduce((acc, page) => {
    if (!acc[page.group]) {
      acc[page.group] = []
    }
    acc[page.group].push(page)
    return acc
  }, {})

  return (
    <AppBar position="static" sx={{ boxShadow: "none", marginBottom: "15px" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
          <IconButton
            color="inherit"
            onClick={handleToggleDrawer}
            edge="start"
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={"/assets/images/logo.png"}
            alt="eventphotoshot"
            style={{
              maxWidth: "250px",
              maxHeight: "100%",
              objectFit: "contain",
              padding: "3px 50px 2px 0",
            }}
          />
        </Box>

        <Box sx={{ display: "flex" }}>
          {Object.keys(groupPages).map((group) => (
            <Box key={group} sx={{ display: "flex", alignItems: "center" }}>
              {groupPages[group].map((item) => (
                <Button
                  key={item.text}
                  onClick={() => handleCloseNavMenu(item.href)}
                  sx={{
                    color: location.pathname === item.href ? "#fff" : "#999",
                    display: { md: "flex", xs: "none" },
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                  {item.text}
                </Button>
              ))}
            </Box>
          ))}
        </Box>
      </Toolbar>

      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ width: 240 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "8px",
          }}
        >
          <IconButton onClick={handleToggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: "16px" }}>
          {Object.keys(groupPages).map((group) => (
            <React.Fragment key={group}>
              <Typography
                variant="้h6"
                color="secondary"
                sx={{ my: 1, fontWeight: "bold" }}
              >
                {group}
              </Typography>
              {groupPages[group].map((item) => (
                <Button
                  key={item.text}
                  onClick={() => handleCloseNavMenu(item.href)}
                  sx={{
                    my: 2,
                    color: location.pathname !== item.href ? "#ababab" : "#000",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  {item.icon}
                  {item.text}
                </Button>
              ))}
            </React.Fragment>
          ))}
        </Box>
      </Drawer>
    </AppBar>
  )
}
