import React from "react"
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  Button,
} from "@mui/material"
import { Instagram, Facebook, Twitter, GitHub } from "@mui/icons-material"

const Footer = () => {
  const socialLinks = [
    {
      href: "https://www.instagram.com/eventphotoshotofficial",
      label: "Instagram",
      icon: <Instagram />,
    },
    {
      href: "https://www.facebook.com/profile.php?id=61565511707854",
      label: "Facebook",
      icon: <Facebook />,
    },
    {
      href: "https://www.twitter.com/eventphotoshot",
      label: "Twitter",
      icon: <Twitter />,
    },
  ]

  const stockLinks = [
    {
      href: "https://www.shutterstock.com/g/JayTrairat",
      label: "JayTrairat",
      icon: (
        <img
          src="/assets/images/shutterstock.png"
          style={{ width: "20px" }}
          alt="Shutterstock"
        />
      ),
    },
    {
      href: "https://stock.adobe.com/th/contributor/208143220/Trirat",
      label: "TRAIRAT",
      icon: (
        <img
          src="/assets/images/adobe.png"
          style={{ width: "20px" }}
          alt="Adobe"
        />
      ),
    },
    {
      href: "https://github.com/jaytrairat",
      label: "JAYTRAIRAT",
      icon: <GitHub />,
    },
  ]

  const footerStyle = {
    backgroundColor: "#2c2c2c",
    color: "white",
    py: 4,
    mt: 5,
  }

  const buttonStyle = {
    color: "black",
  }

  return (
    <Box sx={footerStyle}>
      <Container>
        {/* Social Links */}
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="body2">Social Network:</Typography>
          </Grid>
          {socialLinks.map(({ href, label, icon }) => (
            <Grid item key={label}>
              <IconButton
                color="inherit"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={label}
              >
                {icon}
              </IconButton>
            </Grid>
          ))}
        </Grid>

        {/* Stock & Coding Links */}
        <Grid
          container
          mt={2}
          gap={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2">Stock photo & Coding:</Typography>
          </Grid>
          {stockLinks.map(({ href, label, icon }) => (
            <Grid item key={label}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                sx={buttonStyle}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                startIcon={icon}
              >
                {label}
              </Button>
            </Grid>
          ))}
        </Grid>

        {/* Footer Text */}
        <Box mt={3} textAlign="center">
          <Typography variant="body2">
            EVENTPHOTOSHOT. ยินดีต้อนรับครับ @ {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
