import { Container, Box } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import Contact from "./Contact/Contact"
import Gallery from "./Gallery/Gallery"
import Nav from "./Header/Header"
import Footer from "./Footer/Footer"

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#221204",
      },
      secondary: {
        main: "#ad657f",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            boxShadow: "none",
          },
        },
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <BrowserRouter>
          <Nav />
          <Box sx={{ flex: 1 }}>
            <Routes>
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Gallery />} />
            </Routes>
          </Box>
          <Footer />
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  )
}

export default App
