import React, { useState, useEffect } from "react"
import { Grid, Box, Typography } from "@mui/material"
function PictureHolders({ set }) {
  const [gallery, setGallery] = useState([])

  useEffect(() => {
    fetch("/portfolio/portfolio.json")
      .then((response) => response.json())
      .then((data) => setGallery(data))
  }, [])

  return (
    <Grid container>
      {gallery
        .filter((item) => item.set === set)
        .map((item, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Box
              onClick={() => {
                window.open(item.url, "_blank")
              }}
              sx={{
                position: "relative",
                width: "100%",
                height: 300,
                backgroundImage: `url(${`/portfolio/${item.image}`})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    transitionDuration: "0.2s",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <Typography
                  variant="h4"
                  color="#fff"
                  backgroundColor="rgba(0, 0, 0, 0.5)"
                  textAlign="center"
                  sx={{ width: "100%" }}
                >
                  {item.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  )
}

export default PictureHolders
