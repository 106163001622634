import Facebook from "@mui/icons-material/Facebook"
import LocalPhone from "@mui/icons-material/LocalPhone"
import { Button, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"
export default function Contact() {
  return (
    <Grid container sx={{ background: "#fff", padding: "15px", gap: "5px" }}>
      <Grid item xs={12}>
        <Typography variant="h6" textAlign="center" color="initial">
          ช่องทางติดต่อ
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" fontWeight="bold">
          📷 บันทึกความทรงจำในทุกอารมณ์ 📷
        </Typography>
      </Grid>
      <Grid item sx={{ background: "#eee", padding: "15px 15px 25px 15px" }}>
        <Typography variant="body1" sx={{ textIndent: "55px" }}>
          ให้ผมเป็นผู้ช่วยบันทึกความทรงจำในงานอีเวนต์ของท่าน
          ผมเป็นช่างภาพเน้นการถ่ายภาพแนว Candid ด้วยราคาสมเหตุสมผล
          คุณภาพงานสามารถนำไปใช้งานได้ ไม่ว่าจะเป็นงานแต่ง งานเลี้ยง
          หรือกิจกรรมพิเศษต่าง ๆ
          มั่นใจได้ว่าทุกโมเมนต์ของท่านถูกบันทึกไว้อย่างสวยงาม เป็นธรรมชาติ
        </Typography>
        <Typography variant="body1" sx={{ marginTop: "15px" }}>
          ✨ ติดต่อผมได้ตามช่องทางด้านล่าง เพื่อนัดวันเก็บความทรงจำครับ ✨
        </Typography>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          sx={{ background: "#1877f2", color: "#fff", borderColor: "#fff" }}
          startIcon={<Facebook />}
          onClick={() => {
            window.open(
              "https://www.facebook.com/profile.php?id=61565511707854",
              "_blank"
            )
          }}
        >
          eventphotoshot
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          sx={{ background: "#06c755", color: "#fff", borderColor: "#fff" }}
          startIcon={
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/line-brands-solid.svg`}
              alt="Line"
              style={{ width: 20, height: "auto" }}
            />
          }
          onClick={() => {
            window.open("https://line.me/R/ti/p/@359osrsn", "_blank")
          }}
        >
          eventphotoshot
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="outlined"
          sx={{ background: "#aaa", borderColor: "#aaa", color: "#fff" }}
          startIcon={<LocalPhone />}
          onClick={() => {
            window.open(`tel:+66869444488`, "_self")
          }}
        >
          โทรศัพท์ 08-6944-4488
        </Button>
      </Grid>
    </Grid>
  )
}
