import React from "react"
import PictureHolders from "../PictureHolders/PictureHolders"
import { Typography, Grid, Box } from "@mui/material"
export default function Gallery() {
  return (
    <Grid container sx={{ gap: "15px" }}>
      <Grid item xs={12}>
        <Typography variant="h6" color="#fff" textAlign="center">
          ภาพงาน Event
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container sx={{ gap: "15px" }}>
          <PictureHolders set="event" />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color="#fff" textAlign="center">
          ภาพท่องเที่ยว
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container sx={{ gap: "15px" }}>
          <PictureHolders set="travel" />
        </Grid>
      </Grid>
      <Grid>
        <Box sx={{ minHeight: '5vh' }}></Box>
      </Grid>
    </Grid>
  )
}
